<template>
  <db-searchable-table v-model="filters" @changed="getData" @row-clicked="showDetails" :items="visits" :fields="fields" :total-rows="total" hover pagination no-border no-body-gap>
    <template #company="data">
      <span v-b-tooltip.hover.top="data.item.company.name.length > 20 ? data.item.company.name : ''">{{ $fn(data.item.company.name, 20) }}</span>
    </template>
    <template #physician="data">
      <app-user-info v-if="data.item.physician" :title="data.item.physician.auth.fullname" :alt_title="$physicianRole(data.item.physicianRole)" icon="" variant="secondary" />
      <span v-else> - </span>
    </template>
    <template #status="{ item }">
      <b-badge :variant="$variants[item.status.key]">
        {{ item.status.value }}
      </b-badge>
    </template>
  </db-searchable-table>
</template>

<script>
import VisitLayout from "./VisitLayout.vue";
export default {
  components: { VisitLayout },
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    visits() {
      return this.$store.getters.visits?.data ?? [];
    },
    total() {
      return this.$store.getters.visits?.total ?? 0;
    },
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
    fields() {
      return this.currentBreakPoint === "xs"
        ? [
            { key: "branch.name", label: "Sicil Adı" },
            {
              key: "startDate",
              label: "Ziyaret Tarihi",
              formatDate: true,
            },
          ]
        : [
            { key: "branch.name", label: "Sicil Adı" },
            {
              key: "startDate",
              label: "Ziyaret Tarihi",
              formatDate: true,
            },
            {
              key: "physician",
              label: "Görevli",
            },
            {
              key: "createdAt",
              label: "Oluşturma Tarihi",
              formatDateTime: true,
            },
            {
              key: "status",
              label: "Durum",
            },
          ];
    },
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  methods: {
    getData() {
      this.$store.dispatch("getVisits", { query: { company: this.company, ...this.filters }, actionType: "setVisits" });
    },
    showDetails(item) {
      this.$router.push({ name: "customer-visit-detail", params: { id: item?._id } });
    },
  },
  destroyed() {
    this.$store.commit("setVisits", []);
  },
};
</script>

<style></style>

<!-- <template>
  <app-overlay>
    <b-card body-class="p-1">
      <b-row style="gap: 10px 0px">
        <b-col class="d-flex flex-wrap align-items-center gap-5" lg="8">
          <ul class="nav nav-pills m-0">
            <li v-for="(item, index) in buttons" :key="index" class="nav-item">
              <a class="nav-link" :class="{ active: tabIndex === index }" @click="tabIndex = index">{{ item }}</a>
            </li>
          </ul>
        </b-col>
        <b-col class="d-flex align-items-center gap-10" lg="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input id="filterInput" v-model="filter" placeholder="Ara..." type="search" />
          </b-input-group>
          <b-button v-b-toggle.filter-collapse variant="gradient-secondary" class="btn-icon rounded-circle" style="padding: 4px">
            <mdi-icon icon="FilterVariant" size="24" />
          </b-button>
        </b-col>
      </b-row>
      <b-collapse id="filter-collapse">
        <b-card class="border mb-0 mt-1">
          <b-row>
            <b-col md="4">
              <app-date-input name="Tarih Aralığı" placeholder="Tarih ile filtreleyin..." mode="range" @input="setDateQuery" />
            </b-col>
            <b-col md="4">
              <app-select-input
                v-model="query['physician._id']"
                name="Uzman:"
                :reduce="(option) => option.id"
                :options="physicianOptions"
                placeholder="Uzman Seçiniz..."
                select_label="value"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
      <b-tabs v-model="tabIndex">
        <b-tab title-item-class="d-none">
          <app-table :on-filter="filter" :items="filteredVisits" :fields="fields" hover pagination @row-clicked="showDetails">
            <template #physician="data">
              <app-user-info
                v-if="data.item.physician"
                :title="data.item.physician.auth.fullname"
                :alt_title="$physicianRole(data.item.physicianRole)"
                icon=""
                variant="secondary"
              />
              <span v-else> - </span>
            </template>
            <template #status>
              <b-badge variant="danger"> Yapılmadı </b-badge>
            </template>
          </app-table>
        </b-tab>
        <b-tab title-item-class="d-none">
          <app-calendar ref="visitcalendar" :data="calendarVisits" @event:click="showDetails" />
        </b-tab>
      </b-tabs>
    </b-card>
  </app-overlay>
</template>

<script>
import VisitDetailModal from "./details/VisitDetailModal.vue";

export default {
  data() {
    return {
      tabIndex: 0,
      filter: null,
      query: {
        startDate: null,
        endDate: null,
        date: null,
      },
      buttons: ["Liste", "Takvim"],
      fields: [
        { key: "physician", label: "Görevli Personel", sortable: true },
        { key: "branch.name", label: "Sicil", sortable: true },
        {
          key: "startDate",
          label: "Ziyaret Tarihi",
          sortable: true,
          formatDateTime: true,
        },
        {
          key: "createdAt",
          label: "Oluşturma Tarihi",
          sortable: true,
          formatDateTime: true,
        },
        { key: "status", label: "Durum", sortable: true },
      ],
    };
  },
  computed: {
    visits() {
      return this.$store.getters.visits;
    },
    company() {
      return this.$store.getters?.selectedCompany?._id
    },
    filteredVisits() {
      return this.filterByFields([...this.visits]);
    },
    calendarVisits() {
      return this.filteredVisits.map((item) => ({
        title: `${item?.branch?.name ? item.branch.name : "-"}`,
        date: item.startDate,
        ...item,
      }));
    },
    physicianOptions() {
      return this.$getRepeatedElements(this.visits, { id: "physician?._id", value: "physician?.auth?.fullname" });
    },
  },
  watch: {
    tabIndex(val) {
      if (val === 1) this.showCalendar();
    },
  },
  mounted() {
    this.getData();
  },

  destroyed() {
    this.$store.commit("setVisits", []);
  },

  methods: {
    showCalendar() {
      this.$nextTick(() => {
        this.$refs.visitcalendar.refetch();
      });
    },
    getData() {
      this.company && this.$store.dispatch("getVisits", { query: { company: this.company }, actionType: "setVisits" });
    },
    showDetails(item) {
      this.$store.commit("setVisit", item);
      this.$showAppModal({
        title: "Ziyaret Detayları",
        component: VisitDetailModal,
        size: "custom",
        centered: false,
      });
    },
    setDateQuery(dates) {
      const data = dates?.split(" - ");
      if (data && data.length > 0) data?.length === 1 ? (this.query.date = data[0]) : (this.query = { startDate: data[0], endDate: data[1] });
      else this.query = { startDate: null, endDate: null, date: null };
    },
    filterByFields(items) {
      return this.$dynamicArrayFilter(items, this.query, "startDate");
    },
  },
};
</script>
 -->
