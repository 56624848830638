<template>
  <visit-layout>
    <template #list>
      <visit-table />
    </template>
    <template #calendar>
      <visit-calendar />
    </template>
  </visit-layout>
</template>

<script>
import VisitLayout from "./VisitLayout.vue";
import VisitTable from "./VisitTable.vue";
import VisitCalendar from "./VisitCalendar.vue";

export default {
  components: { VisitLayout, VisitTable, VisitCalendar },
};
</script>

<style></style>
