<template>
  <app-overlay>
    <b-card class="overflow-hidden" body-class="p-1">
      <b-row style="gap: 10px 0px">
        <!-- <b-col class="d-flex flex-wrap align-items-center gap-5" lg="4">
          <app-button class="flex-1-md" text="Ziyaret Planla" icon="PlusIcon" size="md" @click="$showAppSidebar('Ziyaret Planla', VisitCreateSidebar)" />
        </b-col>
 -->
        <b-col lg="8">
          <div class="d-lg-flex justify-content-end">
            <ul style="flex-wrap: nowrap" class="nav nav-pills m-0">
              <li @click="checkMounted" v-for="(item, index) in buttons" :key="index" class="nav-item">
                <a class="nav-link" :class="{ active: tabIndex === index }" @click="tabIndex = index">{{ item }}</a>
              </li>
            </ul>
          </div>
        </b-col>
        <!-- Search and filter -->
        <b-col class="d-flex align-items-center gap-10 border-left" lg="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input id="filterInput" v-model="filter" placeholder="Ara..." type="search" />
          </b-input-group>
          <b-button v-b-toggle.filter-collapse variant="gradient-secondary" class="btn-icon rounded-circle" style="padding: 4px">
            <mdi-icon icon="FilterVariant" size="24" />
          </b-button>
        </b-col>
      </b-row>
      <!-- Filter collapse -->
      <slot name="filter" />
      <b-tabs v-model="tabIndex">
        <b-tab title-item-class="d-none">
          <slot v-bind="{ filter }" name="list" />
        </b-tab>
        <b-tab title-item-class="d-none" :lazy="!mountedTabs.includes(1)">
          <slot name="calendar" v-bind="{ tabIndex }" />
        </b-tab>
      </b-tabs>
    </b-card>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      filter: null,
      buttons: ["Liste", "Takvim"],
      mountedTabs: [],
    };
  },
  methods: {
    checkMounted() {
      if (!this.mountedTabs.includes(1)) {
        this.mountedTabs.push(1);
      }
    },
  },
};
</script>
