<template>
  <app-calendar ref="visitcalendar" :data="visits" @event:click="showDetails" @dates:set="datesSet" />
</template>

<script>
export default {
  data() {
    return {
      visits: [],
      dates: {},
    };
  },
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/visits/list/calendar", {
          params: {
            company: this.company,
            ...this.dates,
          },
          loading: "table",
        })
        .then((result) => {
          this.visits = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    datesSet(dates) {
      this.dates = dates;
      this.getData();
    },
    showDetails(item) {
      this.$router.push({ name: "customer-visit-detail", params: { id: item?._id } });
    },
  },
};
</script>

<style></style>
